import { createModel } from "@rematch/core";
import http from "../utils/http";
import { replaceMessagesFilesPaths } from "../utils/text";
import { mapAreaChartData, mapBarChartData, mapPieChartData } from "../mappers/chartsMapper";

const ThreadStore = createModel()({
  name: "thread",
  state: {
    thread: {},
    historyList: [],
    threadId: 0,
    loading: false,
    showWelcomeMessage: true,
    lastMessage: "",
    lastStream: "",
    showTypingEffect: false,
    location: "",
    showStreaming: false,
    chartData: null,
    hitsResults: null,
  },
  reducers: {
    hideWelcomeMessageReducer(state, payload) {
      return {
        ...state,
        showWelcomeMessage: payload,
      };
    },
    emptyThreadReducer(state, payload) {
      return {
        ...state,
        thread: {},
        threadId: 0,
      };
    },
    loadingReducer(state, payload) {
      return {
        ...state,
        loading: payload,
      };
    },
    sendPromptReducer(state, payload) {
      return {
        ...state,
        thread: payload,
      };
    },
    getHistoryReducer(state, payload) {
      return {
        ...state,
        historyList: payload,
      };
    },
    setThreadIdReducer(state, payload) {
      return {
        ...state,
        threadId: payload,
      };
    },
    setLastMessageReducer(state, payload) {
      return {
        ...state,
        lastMessage: payload,
      };
    },
    setLastStreamReducer(state, payload) {
      return {
        ...state,
        lastStream: payload,
      };
    },
    showTypingEffectReducer(state, payload) {
      return {
        ...state,
        showTypingEffect: payload,
      };
    },
    showStreamingReducer(state, payload) {
      return {
        ...state,
        showStreaming: payload,
      };
    },
    changeLocationReducer(state, payload) {
      return {
        ...state,
        location: payload,
      };
    },
    startNewThreadReducer(state, payload) {
      return {
        ...state,
        threadId: payload,
        thread: {},
      };
    },
    setChartData(state, payload) {
      return {
        ...state,
        chartData: payload,
      };
    },
    clearChartData(state, payload) {
      return {
        ...state,
        chartData: null
      }
    },
    setHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: payload,
      };
    },
    clearHitsResults(state, payload) {
      return {
        ...state,
        hitsResults: null
      }
    }
  },
  effects: (dispatch) => ({
    async emptyThread(payload, state) {
      dispatch.thread.emptyThreadReducer();
    },
    async setLastStream(payload, state) {
      dispatch.thread.setLastStreamReducer(payload);
    },
    async changeLocation(payload, state) {
      dispatch.thread.changeLocationReducer(payload);
    },
    async startNewThread(payload, state) {
      const { users } = state;
      if (users.logedIn) {
        const thread = await http.post(`/thread?lngOwnerId=${users.data.id}&assistantType=${payload}`);

        dispatch.thread.startNewThreadReducer(thread.data.id);
      }
    },
    async sendPrompt(payload, state) {
      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.text);
      dispatch.thread.hideWelcomeMessageReducer(false);
      const formData = new FormData();
      if (payload.files) {
        for (const file of payload.files) {
          formData.append("files", file);
        }
      }
      if (payload.text) {
        formData.append("message", payload.text);
      }
      if (state.thread.location) {
        formData.append("assistantType ", state.thread.location);
      }

      let res = {};

      try {
        res = await http.post(
          `/thread/${payload.threadId}/message?runAssistant=false`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch.thread.sendPromptReducer(res.data);
        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);
      } catch {
        dispatch.thread.loadingReducer(false);
      }
    },
    async sendFalakPrompt(payload, state) {
      const { users } = state;
      const formData = new FormData();

      dispatch.thread.showStreamingReducer(true);
      dispatch.thread.loadingReducer(true);
      dispatch.thread.setLastMessageReducer(payload.prompt);
      dispatch.thread.hideWelcomeMessageReducer(false);

      try {
        // TODO: use this code for handling charts when needed
        // const res = await http.post(
        //   `https://stage.7saip.com/documentai/generate-charts`,
        //   formData
        // );
        // let data = {};
        // switch (res.data.type) {
        //   case 'bar':
        //     data = mapBarChartData(res);
        //     break;
        //   case 'area':
        //     data = mapAreaChartData(res);
        //     break;
        //   case 'pie':
        //     data = mapPieChartData(res);
        //     break;

        if (payload.files.length > 0) {
          for (const file of payload.files) {
            formData.append("file", file);
          }
          await http.post(
            `/files/upload?lngOwnerId=${users.data.id}`,
            formData
          );
        }

        if (payload.prompt) {
          const searchRes = await http.get(
            `/files/search?lngOwnerId=${users.data.id}&query=${payload.prompt}`,
          );
          dispatch.thread.setHitsResults(searchRes.data)
        }

        dispatch.thread.showTypingEffectReducer(true);
        dispatch.thread.loadingReducer(false);

        // TODO: use this code for handling charts when needed
        // dispatch.thread.setChartData({
        //   data,
        //   axisDataKey: res.data.data.datasets[0].label,
        //   barDataKey: 'value',
        //   type: res.data.type,
        // })
      } catch {
        dispatch.thread.loadingReducer(false);
      }
    },
    async getHistory(payload, state) {
      const { users } = state;
      const history = await http.get(`thread?lngOwnerId=${users.data.id}&assistantType=${payload}`);
      dispatch.thread.getHistoryReducer(history.data);
    },
    async getThread(payload, state) {
      dispatch.thread.showStreamingReducer(false);
      dispatch.thread.setThreadIdReducer(payload.id);
      dispatch.thread.showTypingEffectReducer(false);

      const thread = await http.get(`thread/${payload.id}/message?assistantType=${payload.assistantType}`);
      replaceMessagesFilesPaths(thread.data.messages);

      dispatch.thread.sendPromptReducer(thread.data);
      dispatch.drawer.openDrawer(false);
    },
  }),
});

export default ThreadStore;
