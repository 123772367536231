import { createModel } from "@rematch/core";

const ThemeStore = createModel()({
  name: "theme",
  state: {
    dark: true,
  },
  reducers: {
    darkReducer(state, payload) {
      return {
        ...state,
        dark: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async changeColor(payload) {
      dispatch.theme.darkReducer(payload);
    },
  }),
});
export default ThemeStore;
