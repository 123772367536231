import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primeicons/primeicons.css';
import {RouterProvider} from "react-router-dom";
import router from "./routes";
import {Provider} from "react-redux";
import store from "./store";
import I18nProvider from "./locale/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nProvider>
                <RouterProvider router={router}/>
            </I18nProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
