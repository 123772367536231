import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Najm from "./pages/Najm";

const App = lazy(() => import("./App"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const Shehab = lazy(() => import("./pages/Shehab"));
const Ghaith = lazy(() => import("./pages/Ghith"));
const Home = lazy(() => import("./pages/Home"));
const GaithDynamicpages = lazy(() => import("./pages/gaithDynamicpages"));
const Falak = lazy(() => import("./pages/Falak"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<></>}>
        <App />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<></>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/shehab",
        element: (
          <Suspense fallback={<></>}>
            <Shehab />
          </Suspense>
        ),
      },
      {
        path: "/ghaith",
        element: (
          <Suspense fallback={<></>}>
            <Ghaith />
          </Suspense>
        ),
      },
      {
        path: "/ghaith/:id",
        element: (
          <Suspense fallback={<></>}>
            <GaithDynamicpages />
          </Suspense>
        ),
      },
      {
        path: "/falak",
        element: (
          <Suspense fallback={<></>}>
            <Falak/>
          </Suspense>
        ),
      },
      {
        path: "/najm",
        element: (
          <Suspense fallback={<></>}>
            <Najm/>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<></>}>
        <LoginPage />
      </Suspense>
    ),
  },
]);

export default router;
