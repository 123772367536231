import {createModel} from "@rematch/core";

const drawerStore = createModel()({
    name: "drawer",
    state: {
        open: false,
        openSettings: false,
    },
    reducers: {
        openDrawerReducer(state, payload) {
            return {
                ...state,
                open: payload,
            };
        },
        openSettingsDrawerReducer(state, payload) {
            return {
                ...state,
                openSettings: payload,
            };
        },
    },
    effects: (dispatch) => ({
        async openDrawer(payload) {
            dispatch.drawer.openDrawerReducer(payload);
        },
        async openSettingsDrawer(payload) {

            dispatch.drawer.openSettingsDrawerReducer(payload);
        },
    }),
});
export default drawerStore;
