import { mkTypes } from "../components/markdown";

export const isArabic = (text = "") => {
  const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
  return arabicRegex?.test(text);
};

export const sanitizeText = (text) =>
  text?.replace(/\*\*/g, "")?.replace(/###/g, "");

export const processCombinedText = (text) => {
  const paragraphs = text.split('\n\n');
  return paragraphs.map((paragraph) => ({
    text: paragraph,
    direction: isArabic(paragraph) ? 'rtl' : 'ltr',
  }));
};

export const replaceMessagesFilesPaths = (messages) => {
  if (messages) {
    messages.forEach(message => {
      if (message.files.length) {
        message.files.forEach(file => {
          const filePath = mkTypes.file_path(file.fileId);
          message.content = message.content.replace(file.text, filePath);
        })
      }
    });
  }
}
