import { createModel } from "@rematch/core";

let users = [
  { id: 1, user: "joey.t", password: "qwer123", name: "Mat L" },
  { id: 2, user: "ross.g", password: "asdf456", name: "David S" },
  { id: 3, user: "monika.g", password: "zxcv789", name: "Courtney C" },
  { id: 4, user: "rachel.g", password: "poiu987", name: "Jen A" },
  { id: 5, user: "chandler.b", password: "lkjh654", name: "Matt Berry" },
];

const UserStore = createModel()({
  name: "users",
  state: {
    data: {},
    logedIn: false,
  },
  reducers: {
    logedInReducerData(state, payload) {
      return {
        ...state,
        data: payload,
      };
    },
    logedInReducer(state, payload) {
      return {
        ...state,
        logedIn: payload,
      };
    },
    logOutReducer(state, payload) {
      return {
        ...state,
        logedIn: payload,
        data: {}, // Clear user data on logout
      };
    },
  },
  effects: (dispatch) => ({
    async logout() {
      dispatch.users.logOutReducer(false);
    },
    async login({ username, password }) {
      const user = users.find(
        (n) => n.user === username && n.password === password
      );
      if (user) {
        dispatch.users.logedInReducerData(user);
        dispatch.users.logedInReducer(true);
      } else {
        dispatch.users.logedInReducerData(null);
        dispatch.users.logedInReducer(false);
      }
    },
  }),
});

export default UserStore;
