import React from "react";

const Najm = () => {
  return (
    <div className="h-screen pt-12">
      <iframe
        src="https://stage.7saip.com/digital-human-ui"
        className="h-full w-full"
        allow="microphone"
      ></iframe>
    </div>
  );
};

export default Najm;
