import { init } from "@rematch/core";
import drawerStore from "./DrawerStore";
import userStore from "./UsersStore";
import ThreadStore from "./ThreadStore";
import ThemeStore from "./ThemeStore";

import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";

const models = {
  drawerStore,
  userStore,
  ThreadStore,
  ThemeStore,
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["loading", "popup", "drawer"],
};
const store = init({
  plugins: [persistPlugin(persistConfig)],
  models,
});

export default store;
